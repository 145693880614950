<template>
  <section>
    <div v-if="ad.ads_id" class="u-mrgn-bottom--3">
      <a
        data-testid="pdp-ads"
        :href="adsUrl"
        target="_blank"
        @click="trackAds($options.TRACKER_ACTION.adsClick)"
      >
        <img style="width: 100%" :src="ad.ads_image" :alt="ad.ads_name" />
      </a>
    </div>
    <section v-else-if="dataFetched" data-testid="no-pdp-ads" />
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { productDetailAction } from '~shared-js/tracker/rage';
import { neo } from '~pdp/js/modules/api-modules';

export default {
  name: 'BlAdsMv',

  TRACKER_ACTION: {
    adsShown: 'has_pdp_ads',
    adsClick: 'click_pdp_ads',
  },

  data() {
    return {
      ad: {},
      dataFetched: false,
    };
  },

  computed: {
    ...mapState(['category']),

    adsUrl() {
      return this.ad.ads_destination;
    },

    productCategoryString() {
      return this.category.structure.join(' - ').toLowerCase();
    },
  },

  mounted() {
    this.fetchAdsConfig();
  },

  methods: {
    trackAds(action) {
      productDetailAction.track({
        action,
        campaignId: this.adsUrl,
      });
    },

    async fetchAdsConfig() {
      try {
        const res = await neo.$configs.postConfigs({
          data: { id: ['pdp-ads-web'] },
        });

        const config = res.data.find((c) => c.id === 'pdp-ads-web');

        if (config?.data) {
          const ads = this.filterAds(config.data?.ads || [], this.productCategoryString);
          this.ad = ads.length ? ads[ads.length - 1] : {};

          if (this.ad.ads_id) {
            this.trackAds(this.$options.TRACKER_ACTION.adsShown);
          }
        }

        this.dataFetched = true;
      } catch (error) {
        console.error(error);
      }
    },

    filterAds(ads = [], categoryStr) {
      return ads.filter(
        (ad) =>
          ad.enabled &&
          ad.platform.includes('desktop') &&
          ad.categories.split(';').some((category) => {
            const cat = category.trim().toLowerCase();
            return categoryStr.includes(cat);
          }),
      );
    },
  },
};
</script>
